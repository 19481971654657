import React, { FC } from 'react';
import styles from './Header.scss';
import { InfoButton } from '../../../../common/components/InfoButton/InfoButton';
import { TextButton } from '../../../../common/components/TextButton/TextButton';
import { useChannel, useChannelAccessButton } from '../../../../hooks';
import { TextButtonPriority } from 'wix-ui-tpa';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../../../../stylesParams';
import { HeaderAccountInfo } from '../../HeaderAccountInfo/HeaderAccountInfo';

const useHeaderSettings = () => {
  const styles = useStyles();
  const showTitle = styles.get(stylesParams.showChannelTitle);
  const showChannelShare = styles.get(stylesParams.showChannelShare);
  const showChannelInfo = styles.get(stylesParams.showChannelInfo);
  const showSignIn = styles.get(stylesParams.showSignIn);
  return { showTitle, showChannelShare, showChannelInfo, showSignIn };
};

const ChannelInfoButton: FC = () => <InfoButton />;

const ChannelAccessButton: FC = () => {
  const channelAccessButton = useChannelAccessButton();
  if (!channelAccessButton) {
    return null;
  }
  return (
    <TextButton
      onClick={() => channelAccessButton.handler()}
      priority={TextButtonPriority.secondary}
    >
      {channelAccessButton.text}
    </TextButton>
  );
};

const ButtonWrapper: FC = ({ children }) => (
  <div className={styles.buttonWrapper}>{children}</div>
);

export const Header: FC<{ renderShareButton: () => React.ReactNode }> = ({
  renderShareButton,
}) => {
  const channel = useChannel();
  const channelAccessButton = useChannelAccessButton();

  const { showTitle, showChannelInfo, showChannelShare, showSignIn } =
    useHeaderSettings();

  return (
    <header className={styles.root}>
      <h2 className={styles.title} data-hook="header-title">
        {showTitle ? channel.title : null}
      </h2>
      <div className={styles.buttons}>
        {showChannelShare ? (
          <ButtonWrapper>{renderShareButton()}</ButtonWrapper>
        ) : null}
        {showChannelInfo ? (
          <ButtonWrapper>
            <ChannelInfoButton />
          </ButtonWrapper>
        ) : null}
        {channelAccessButton ? (
          <ButtonWrapper>
            <ChannelAccessButton />
          </ButtonWrapper>
        ) : null}
        {showSignIn ? (
          <ButtonWrapper>
            <HeaderAccountInfo />
          </ButtonWrapper>
        ) : null}
      </div>
    </header>
  );
};
