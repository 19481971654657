import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import s from './Classic.scss';
import { DesktopPlayer } from '../DesktopPlayer/DesktopPlayer';
import { VideoAspectRatio } from '../../../common/components/VideoAspectRatio/VideoAspectRatio';
import { Header } from './Header/Header';
import { Videos } from './Videos/Videos';
import { usePlayback, useSelectedVideo, useVideoSource } from '../../../hooks';
import { Search } from './Search/Search';
import { Categories } from './Categories/Categories';
import { NoResult } from './Search/NoResult/NoResult';
import { useVideosFilter } from '../../../hooks/useVideosFilter';
import { useChannelVideos } from '../../../hooks/useChannelVideos';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../../../stylesParams';
import { ChannelOverlay } from './ChannelOverlay/ChannelOverlay';
import { VideoOverlay } from './VideoOverlay/VideoOverlay';
import { ComingSoon } from './ComingSoon/ComingSoon';
import { SelectedVideoProvider } from '../../../common/providers/SelectedVideoProvider';
import { useChannelContext } from '../../../hooks/useChannel';
import { PlaybackProvider } from '../../../common/providers/PlaybackProvider';
import { VIDEO_END_OPTIONS } from '@wix/wix-vod-constants/common';
import { PlayerOverlayProvider } from '../PlayerOverlayProvider/PlayerOverlayProvider';
import { DarkShareOverlay } from '../DarkShareOverlay/DarkShareOverlay';
import { ShareChannelButton } from '../../../common/components/ShareChannelButton/ShareChannelButton';
import { Animate } from '../Animate/Animate';
import { MAIN_ITEM_TYPE_VALUES } from '@wix/wix-vod-constants/app-settings';

const useVideoEnd = () => {
  const { pause } = usePlayback();
  const { videos } = useChannelVideos();
  const { selectVideo, selectedVideo } = useSelectedVideo();
  const styles = useStyles();
  const videoEndOption: VIDEO_END_OPTIONS = styles.get(
    stylesParams.videoEndOption,
  );

  const playNextVideo = useCallback(() => {
    const currentIndex = videos.findIndex(({ id }) => id === selectedVideo.id);

    if (currentIndex === videos.length - 1) {
      pause();
    } else {
      const nextVideo = videos[currentIndex + 1];
      selectVideo(nextVideo);
    }
  }, [videos, selectedVideo, selectVideo, pause]);

  return useCallback(() => {
    switch (videoEndOption) {
      case VIDEO_END_OPTIONS.PLAY_NEXT_VIDEO:
        playNextVideo();
        break;
      default:
        pause();
    }
  }, [videoEndOption, playNextVideo, pause]);
};

const useViewSettings = () => {
  const styles = useStyles();
  const showChannelCategories = styles.get(stylesParams.showChannelCategories);
  const showSearch = styles.get(stylesParams.showSearch);
  return { showChannelCategories, showSearch };
};

// TODO: we need to come up with a better name for it
// we have this "overlay" and the one that opens for "video info" or "share", which is PlayerOverlay
// and that one seems to be more overlay than this one.
const useShowChannelOverlay = () => {
  const { selectedVideo, selectedVideoChanged } = useSelectedVideo();
  const { playing } = usePlayback();
  const { isStreamingLive } = useVideoSource(selectedVideo);
  const styles = useStyles();
  const mainItemType: MAIN_ITEM_TYPE_VALUES = styles.get(
    stylesParams.mainItemType,
  );

  const [showChannelOverlay, setShowChannelOverlay] = useState(
    !isStreamingLive && mainItemType === MAIN_ITEM_TYPE_VALUES.CHANNEL_COVER,
  );

  useEffect(() => {
    if (playing || selectedVideoChanged) {
      setShowChannelOverlay(false);
    }
  }, [playing, selectedVideoChanged]);

  return showChannelOverlay;
};

const OverlayWithPlayer: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { selectedVideo } = useSelectedVideo();
  const { playing } = usePlayback();
  const handleVideoEnd = useVideoEnd();
  const showChannelOverlay = useShowChannelOverlay();

  return (
    <VideoAspectRatio>
      <DesktopPlayer
        video={selectedVideo}
        key={selectedVideo.id}
        onEnd={handleVideoEnd}
      />
      {playing ? null : showChannelOverlay ? (
        <ChannelOverlay />
      ) : (
        <VideoOverlay />
      )}
      {children}
    </VideoAspectRatio>
  );
};

const Content: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { videos, videosSearching } = useChannelVideos();
  const { isEmptyFilter } = useVideosFilter();
  const { showChannelCategories, showSearch } = useViewSettings();
  const showNoResult = useMemo(
    () => !isEmptyFilter && !videos?.length && !videosSearching,
    [isEmptyFilter, videos, videosSearching],
  );

  return (
    <SelectedVideoProvider>
      <PlaybackProvider>
        <OverlayWithPlayer>{children}</OverlayWithPlayer>
        <footer className={s.footer}>
          {showSearch ? (
            <div className={s.search}>
              <Search />
            </div>
          ) : null}
          {showChannelCategories ? (
            <div className={s.categories}>
              <Categories />
            </div>
          ) : null}
          <div className={s.videos}>
            <Videos />
            {showNoResult && (
              <div className={s.noSearchResult}>
                <NoResult />
              </div>
            )}
          </div>
        </footer>
      </PlaybackProvider>
    </SelectedVideoProvider>
  );
};

const useShouldRenderComingSoon = () => {
  const channel = useChannelContext();
  const { videos } = useChannelVideos();
  const { isEmptyFilter } = useVideosFilter();

  const shouldRenderComingSoon =
    !channel || (isEmptyFilter && videos.length === 0);

  return {
    hasChannel: Boolean(channel),
    shouldRenderComingSoon,
  };
};

const ShareOverlay: FC<{
  shareShown: boolean;
  closeOverlay: () => void;
}> = ({ shareShown, closeOverlay }) => {
  return (
    <Animate
      className={s.shareOverlay}
      type="fade-in-out"
      open={shareShown}
      dataHook="share-overlay-animate"
    >
      {() => <DarkShareOverlay mode="channel" closeOverlay={closeOverlay} />}
    </Animate>
  );
};

const useShareShown = () => {
  const [shareShown, setShareShown] = useState(false);
  return {
    shareShown,
    closeOverlay: () => setShareShown(false),
    toggleOverlay: () => setShareShown(!shareShown),
  };
};

export const Classic: FC = () => {
  const { shouldRenderComingSoon, hasChannel } = useShouldRenderComingSoon();
  const { closeOverlay, shareShown, toggleOverlay } = useShareShown();

  return (
    <div className={classNames(s.root)} data-hook="classic-layout-root">
      <PlayerOverlayProvider>
        {hasChannel ? (
          <Header
            renderShareButton={() => (
              <ShareChannelButton onClick={toggleOverlay} />
            )}
          />
        ) : null}
        {shouldRenderComingSoon ? (
          <ComingSoon />
        ) : (
          <Content>
            <ShareOverlay shareShown={shareShown} closeOverlay={closeOverlay} />
          </Content>
        )}
      </PlayerOverlayProvider>
    </div>
  );
};
